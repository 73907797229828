export const debounce = <T extends (...args: any[]) => any>(callback: T, waitFor: number) => {
    let timeout: ReturnType<typeof setTimeout>;
    return (...args: Parameters<T>): ReturnType<T> => {
        let result: any;
        timeout && clearTimeout(timeout);
        timeout = setTimeout(() => {
            result = callback(...args);
        }, waitFor);
        return result;
    };
};

// use Ref?
export const memoAsync = <T1, TReturn>(func: (arg: T1) => Promise<TReturn>): ((arg: T1) => Promise<TReturn>) => {
    const cache = {} as any;
    return async function (arg: T1) {
        if (cache[arg]) {
            return cache[arg];
        }
        const result = await func(arg);
        cache[arg] = result;
        return result;
    };
};

export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();

export const isLifestyle = (): boolean => window.location.href.includes("lifestyle");
