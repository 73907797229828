import { useCallback, useEffect, useState } from "react";
import config from "config";
import { memoAsync } from "utils";
import { QASServiceError } from "components/AppErrors";

// Can make this into a hook (useQASService) to make it cleaner and support raising errors from the various methods
// At this point we throw in two places, one if we don't get a response.ok, and another if fetch fails maybe due to a network error
// They allow us used the CustomError

interface FormatResultAddress {
    address_line_1: string;
    address_line_2: string;
    address_line_3: string;
    locality: string;
    region: string;
    postal_code: string;
    country: string;
}

interface FormatResultComponents {
    global_address_key: string;
    // Maybe check with their docs to see what would always be defined?
    building?: {
        building_name?: string;
        building_number?: string;
    };
    country_iso_2?: string;
    country_iso_3?: string;
    country_name?: string;
    language?: string;
    delivery_service?: {
        full_name?: string;
    };
    locality?: {
        town?: {
            name?: string;
        };
    };
    postal_code?: {
        full_name?: string;
        primary?: string;
    };
    street?: {
        full_name?: string;
        name?: string;
        type?: string;
    };
    sub_building?: {
        door?: {
            full_name?: string;
            type?: string;
            value?: string;
        };
    };
}

interface FormatResponseMetadata {
    address_info?: {
        identifier?: {
            gnafPid: string;
        };
    };
}

interface FormatResponseResult {
    global_address_key: string;
    confidence: "Verified match" | string;
    address?: FormatResultAddress;
    components?: FormatResultComponents;
}

export interface FormatResponse {
    result?: FormatResponseResult;
    metadata?: FormatResponseMetadata;
}

export interface EnrichResponse {
    result?: {
        aus_regional_geocodes: {
            latitude: number;
            longitude: number;
        };
    };
}

export const formatAddress = memoAsync(async (format: string): Promise<FormatResponse> => {
    try {
        const response = await fetch(format, {
            method: "GET",
            headers: {
                "Auth-Token": config.qas.token,
                "Add-Components": "true",
                "Add-Metadata": "true",
            },
        });
        if (response.status !== 200) {
            throw new QASServiceError(await response.text());
        }
        return (await response.json()) as FormatResponse;
    } catch (error: any) {
        throw new QASServiceError(error.message);
    }
});

export const enrichAddress = memoAsync(async (globalAddressKey: string): Promise<EnrichResponse> => {
    try {
        const response = await fetch(config.qas.enrichmentUrl, {
            method: "POST",
            headers: {
                "Auth-Token": config.qas.token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                country_iso: "AUS",
                keys: {
                    global_address_key: globalAddressKey,
                },
                attributes: {
                    aus_regional_geocodes: ["latitude", "longitude"],
                },
            }),
        });
        if (response.status !== 200) {
            throw new QASServiceError(await response.text());
        }
        return (await response.json()) as EnrichResponse;
    } catch (error: any) {
        throw new QASServiceError(error.message);
    }
});

interface FormattableAddress {
    format?: string;
}

export const useFormattedAddress = (address: FormattableAddress | undefined): FormatResponse | undefined => {
    const [formattedAddress, setFormattedAddress] = useState<FormatResponse>();
    const fetchFormattedAddress = useCallback(async () => {
        if (address?.format) {
            const formatResponse = await formatAddress(address?.format);
            setFormattedAddress(formatResponse);
        }
    }, [address?.format]);

    useEffect(() => {
        fetchFormattedAddress();
    }, [fetchFormattedAddress]);

    return formattedAddress;
};

export interface Suggestion {
    global_address_key: string;
    text: string;
    matched: string[];
    format: string;
}

export interface SearchResponse {
    result: {
        more_results_available: boolean;
        confidence: "Verified match" | "Insufficient search terms" | "Multiple matches" | string;
        suggestions: Suggestion[];
    };
    // error?
}

export const searchForAddress = async (addressInput: string, type?: "residential" | "postal") => {
    try {
        const dataset = type === "postal" ? config.qas.dataset.postal : config.qas.dataset.residential;
        const response = await fetch(config.qas.url, {
            method: "POST",
            headers: {
                "Auth-Token": config.qas.token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                country_iso: "AUS",
                components: {
                    unspecified: [addressInput],
                },
                dataset: [dataset],
            }),
        });

        if (response.status !== 200) {
            throw new QASServiceError(await response.text());
        }

        return (await response.json()) as SearchResponse;
    } catch (error: any) {
        throw new QASServiceError(error.message);
    }
};
