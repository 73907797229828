// Called template until we're sure if this is a page template or the entire app template
// IE. Is this template directly used from App.tsx only? and every page exists within a router from App.tsx and looks like it? If that's the case then it's an AppTemplate or Template
// It's a PageTemplate when it's individually used by every Page, this is useful when we need more per page customization
// Delete this comment later

import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Footer } from "./Footer";
import { Header, HeaderProps } from "./Header";
import { ResponsiveAll } from "../ThemeWrapper";
import { isLifestyle } from "utils";

interface TemplateProps extends HeaderProps {
    children?: React.ReactNode;
}

export const LifestyleBannerURL = "https://www.datocms-assets.com/62377/1646270019-lifestyle-hero.jpeg";
export const RoadsideBannerURL = "https://www.datocms-assets.com/62377/1646260781-roadside-hero.jpg";

export const Template: React.FC<TemplateProps> = ({ children, hideHeaderImage }) => {
    // Not sure if this is preferred to passing them in as a prop
    // But then the Error Template would have to also do the same
    // If the headerImage, title, meta, headerTitle happens to be different for some other reason other than just different for both funnels
    // Then extract from here and pass in as props

    const title = isLifestyle() ? "Lifestyle by RACT" : "Join RACT Roadside";
    const meta = isLifestyle()
        ? { description: "Enjoy saving with RACT Lifestyle" }
        : { description: "Get onboard with Roadside by RACT" };
    const headerImage = isLifestyle() ? LifestyleBannerURL : RoadsideBannerURL;
    const headerTitle = isLifestyle() ? "Join Lifestyle" : "Join RACT Roadside";

    useEffect(() => {
        document.title = title;
        document.getElementsByTagName("meta").namedItem("description")?.setAttribute("content", meta.description);
    }, [title, meta.description]);

    return (
        <Box
            sx={{
                pl: ResponsiveAll("25px", "25px", "50px", "50px", "75px"),
                pr: ResponsiveAll("25px", "25px", "50px", "50px", "75px"),
                maxWidth: ResponsiveAll("none", "none", "none", "none", "1366px"),
                margin: "auto",
            }}
        >
            <Header headerImage={headerImage} hideHeaderImage={hideHeaderImage} />
            <Box
                sx={{
                    minHeight: "calc(100vh - 460px)",
                    maxWidth: "908px",
                    margin: "auto",
                    pt: ResponsiveAll("25px", "25px", "50px", "50px", "100px"),
                }}
            >
                {/* This should not be swapping between h1 and h2 as in the prototype between desktop and mobile
                In the ThemeWrapper h1 is made responsive then, to switch fontSize and lineHeight for mobile
            */}
                <Typography
                    variant="h1"
                    sx={{
                        textAlign: "center",
                        fontWeight: 500,
                    }}
                >
                    {headerTitle}
                </Typography>
                <Box
                    sx={{
                        pt: 3,
                    }}
                >
                    {children}
                </Box>
            </Box>

            <Footer />
        </Box>
    );
};
