const dollarFormatter = new Intl.NumberFormat("en-AU", {
    style: "currency",
    currency: "AUD",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
});

const dollarAndCentsFormatter = new Intl.NumberFormat("en-AU", {
    style: "currency",
    currency: "AUD",
    minimumFractionDigits: 2,
});

export const currency = (value: number, trimZeroCents: boolean = true): string => {
    if (value % 1 === 0 && trimZeroCents) {
        return dollarFormatter.format(value);
    } else {
        return dollarAndCentsFormatter.format(value);
    }
};
