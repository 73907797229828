import useStore from "state";
import { useEffect } from "react";

export const useOverrideBackButtonOnceComplete = () => {
    const { temporaryMemberNumber } = useStore();

    useEffect(() => {
        const handleBack = (e: PopStateEvent) => {
            if (temporaryMemberNumber.length > 0) {
                document.location.href = "/";
            }
        };

        window.addEventListener("popstate", handleBack);
        return () => {
            window.removeEventListener("popstate", handleBack);
        };
    }, [temporaryMemberNumber]);
};
