import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ThemeOptions } from "@mui/material/styles/createTheme";
import React, { ReactElement, ReactNode } from "react";
import { buttonBaseClasses } from "@mui/material";
import shadows from "@mui/material/styles/shadows";

// TS module augmentation so Typescript understands what we're doing
// when we reference a colour like `theme.palette.primary["100"]`.
declare module "@mui/material/styles/createPalette" {
    interface PaletteColor extends ColorPartial {}
}

// And so we can add a 'green' colour to the theme
declare module "@mui/material/styles" {
    interface Palette {
        green: React.CSSProperties["color"];
        offGrey: React.CSSProperties["color"];
        ractBlue: React.CSSProperties["color"];
        ractRed: React.CSSProperties["color"];
    }
}

const karlaFamily = ["Karla", "Helvetica", "Arial", "sans-serif"].join(",");
const poppinsFamily = ["Poppins", "Helvetica", "Arial", "sans-serif"].join(",");

const palette = {
    primary: {
        50: "#F4F8FD", // Manually derived from the spec of primary.main @ 5% opacity against a white background
        100: "#E9F1FB", // Manually derived from the spec of primary.main @ 10% opacity against a white background
        main: "#2376D8",
        dark: "#1D427C",
        contrastText: "#FFFFFF",
    },
    secondary: {
        main: "#FFA21D",
        light: "#FFF6E8",
        contrastText: "#FFFFFF",
    },
    text: {
        primary: "#666666",
        secondary: "#CCCCCC",
    },
    error: {
        main: "#FF0000",
        50: "#FFE0E0", // Manually derived from the spec of error.main @ 5% opacity against a white background
    },
    green: "#089B1A",
    offGrey: "#8791A1",
    ractBlue: "#204B87",
    ractRed: "#E32C2D",
};

export const themeOptions: ThemeOptions = {
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1366,
        },
    },
    palette,
    typography: {
        allVariants: {
            color: palette.text.primary,
        },
        fontFamily: karlaFamily,
        body1: {
            fontWeight: "normal",
        },
        // Use body2 as the "body bold" style until instructed otherwise
        body2: {
            fontWeight: "600",
            fontSize: 18,
            lineHeight: "26px",
        },
        caption: {
            fontWeight: "normal",
        },
        button: {
            fontWeight: 500,
            lineHeight: "32px",
            textTransform: "capitalize",
        },
        h1: {
            fontFamily: poppinsFamily,
            fontSize: 50,
            lineHeight: "65px",
        },
        h2: {
            fontFamily: poppinsFamily,
            fontSize: 26,
            lineHeight: "39px",
        },
        h3: {
            fontFamily: poppinsFamily,
            color: palette.primary.main,
            fontWeight: 500,
            fontSize: 32,
            lineHeight: "40px",
        },
    },
    components: {
        MuiLink: {
            defaultProps: {
                //whiteSpace: "nowrap",
            },
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    borderRadius: 32,
                    borderWidth: 2,
                    padding: "14px 64px",
                    "&:hover": {
                        borderWidth: 2,
                    },
                    "&.Mui-disabled": {
                        color: palette.primary.contrastText,
                        backgroundColor: palette.primary.main,
                        borderColor: palette.primary.main,
                        borderStyle: "solid",
                        borderWidth: 2,
                        "&:hover": {
                            borderStyle: "solid",
                            borderWidth: 2,
                        },
                    },
                },
                containedSecondary: {
                    "&:hover": {
                        backgroundColor: "#DE8C15",
                    },
                },
                outlinedPrimary: {
                    borderColor: palette.primary.main,
                },
                outlinedSecondary: {
                    borderColor: palette.secondary.main,
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                padding: ({ theme }) => ({
                    padding: "8px 48px",
                    [theme.breakpoints.down("md")]: {
                        padding: "8px 0px",
                    },
                }),
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: ({ theme }) => ({
                    minWidth: 32,
                    [theme.breakpoints.down("md")]: {
                        minWidth: 28,
                    },
                }),
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: 18,
                    lineHeight: "26px",
                },
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    fontSize: 18,
                    lineHeight: "26px",
                    [`&.${buttonBaseClasses.root}`]: {
                        fontSize: 18,
                    },
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: palette.primary.main,
                    color: palette.primary.contrastText,
                    fontSize: 16,
                    lineHeight: "20px",
                    fontFamily: karlaFamily,
                    fontWeight: 400,
                    borderRadius: "20px 20px 0 20px",
                    padding: 16,
                    boxShadow: shadows[3],
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                gutterBottom: ({ theme }) => ({
                    marginBottom: 32,
                    [theme.breakpoints.down("md")]: {
                        marginBottom: 24,
                    },
                }),
            },
        },
    },
};

export const makeThemeWrapper = (themeOptions: ThemeOptions, children?: ReactNode): ReactElement<any, any> | null => {
    const theme = createTheme(themeOptions);

    theme.typography.h1 = {
        ...theme.typography.h1,
        [theme.breakpoints.down("md")]: {
            fontSize: 26,
            lineHeight: "39px",
        },
    };

    theme.typography.h3 = {
        ...theme.typography.h3,
        [theme.breakpoints.down("md")]: {
            fontSize: 20,
            lineHeight: "30px",
        },
    };

    theme.typography.body1 = {
        ...theme.typography.body1,
        fontSize: 22,
        lineHeight: "32px",
        [theme.breakpoints.down("md")]: {
            fontSize: 18,
            lineHeight: "26px",
        },
    };

    theme.typography.caption = {
        ...theme.typography.caption,
        fontSize: 18,
        lineHeight: "24px",
        [theme.breakpoints.down("md")]: {
            fontSize: 14,
            lineHeight: "20px",
        },
    };

    theme.typography.button = {
        ...theme.typography.button,
        fontSize: 24,
        [theme.breakpoints.down("md")]: {
            fontSize: 21,
        },
    };

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export function Responsive<T>(mobile: T, desktop: T): any {
    return {
        xs: mobile,
        sm: mobile,
        md: desktop,
        lg: desktop,
        xl: desktop,
    };
}

export function ResponsiveAll<T>(xs: T, sm: T, md: T, lg: T, xl: T): any {
    return {
        xs: xs,
        sm: sm,
        md: md,
        lg: lg,
        xl: xl,
    };
}

export const ThemeWrapper: React.FC<{ children?: React.ReactNode }> = ({ children }) =>
    makeThemeWrapper(themeOptions, children);
