import { PaymentFrequency, RoadsideProduct } from "CommonTypes";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

export interface ProductDeepLinkingSearchParams {
    product?: RoadsideProduct;
    paymentFrequency?: PaymentFrequency;
    unset: () => void;
}

export const useProductDeepLinking = (): ProductDeepLinkingSearchParams => {
    const [searchParams, setSearchParams] = useSearchParams();
    const productParam = searchParams.get("product");
    const paymentFrequencyParam = searchParams.get("paymentFrequency");
    const productDeepLinking = useMemo(() => {
        return {
            product: productParam
                ? RoadsideProduct[productParam.toLowerCase() as keyof typeof RoadsideProduct]
                : undefined,
            paymentFrequency: paymentFrequencyParam
                ? PaymentFrequency[paymentFrequencyParam.toLowerCase() as keyof typeof PaymentFrequency]
                : undefined,
            unset: () => {
                searchParams.delete("product");
                searchParams.delete("paymentFrequency");
                setSearchParams(searchParams, { replace: true });
            },
        };
    }, [productParam, paymentFrequencyParam, searchParams, setSearchParams]);
    return productDeepLinking;
};
