import { faCircleExclamation } from "@fortawesome/pro-light-svg-icons/faCircleExclamation";
import { faCircleInfo } from "@fortawesome/pro-light-svg-icons/faCircleInfo";
import { faCircleXmark } from "@fortawesome/pro-light-svg-icons/faCircleXmark";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { lighten } from "@mui/system/colorManipulator";

interface infoCardProps {
    variant: "info" | "warning" | "error";
    maxWidth?: number;
    children?: React.ReactNode;
}

const vary = (info: any, warning: any, error: any) => (variant: "info" | "warning" | "error") => {
    switch (variant) {
        case "info":
            return info;

        case "warning":
            return warning;

        case "error":
            return error;
    }
};

export const InfoCard: React.FC<infoCardProps> = ({ variant, maxWidth, children }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Box sx={{ width: maxWidth ? `100%` : "auto", maxWidth: maxWidth ? `${maxWidth}px` : "none" }}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    position: "relative",
                    height: isMobile ? 24 : 35,
                    transition: "inherit",
                }}
            >
                <Box
                    sx={{
                        background: vary(
                            theme.palette.primary["100"],
                            theme.palette.secondary.light,
                            lighten(String(theme.palette.ractRed), 0.9)
                        )(variant),
                        borderColor: vary(
                            theme.palette.primary["100"],
                            theme.palette.secondary.light,
                            lighten(String(theme.palette.ractRed), 0.9)
                        )(variant),
                        borderStyle: "solid",
                        borderWidth: isMobile ? 8 : 10,
                        borderRadius: isMobile ? 24 : 35,
                        width: isMobile ? 28 : 50,
                        height: isMobile ? 28 : 50,
                        position: "absolute",
                        transition: "inherit",
                    }}
                >
                    <FontAwesomeIcon
                        icon={vary(faCircleInfo, faCircleExclamation, faCircleXmark)(variant)}
                        style={{
                            width: isMobile ? 28 : 50,
                            height: isMobile ? 28 : 50,
                            color: vary(
                                theme.palette.primary.main,
                                theme.palette.secondary.main,
                                theme.palette.ractRed
                            )(variant),
                            transition: "inherit",
                        }}
                    />
                </Box>
            </Box>
            <Box
                sx={{
                    backgroundColor: vary(
                        theme.palette.primary["100"],
                        theme.palette.secondary.light,
                        lighten(String(theme.palette.ractRed), 0.9)
                    )(variant),
                    color: theme.palette.text.primary,
                    paddingTop: `${isMobile ? 24 : 35}px`,
                    height: `calc(100% - ${isMobile ? 48 : 70}px)`,
                    transition: "inherit",
                    borderRadius: "20px 0px",
                    pt: theme.spacing(5),
                    paddingX: theme.spacing(3),
                    pb: theme.spacing(4),
                }}
            >
                {children}
            </Box>
        </Box>
    );
};
