import { getTokenisationKey } from "../services/Api";
import { TokenisationKeyResponse } from "../services/Api/Types";
import { PaymentState } from "../state/Types";

declare global {
    interface Window {
        msCrypto: Crypto;
    }
}
export interface CardInfo {
    cardNumber: string;
    cardExpirationMonth: string;
    cardExpirationYear: string;
    cardType: string;
}

export interface TokenisedCard {
    keyId: string;
    token: string;
    maskedPan: string;
    cardType: string;
    timeStamp: number;
    signedFields: string;
    signature: string;
}

export interface TokeniseValidationError {
    location: string;
    message: string;
}

const algorithmDetails = { name: "RSA-OAEP", hash: { name: "SHA-256" } };
const headers = { "Content-Type": "application/json" };

const isValidationError = (r: { status: number; reason: string }) =>
    r.status === 400 && r.reason === "VALIDATION_ERROR";
const getTokenisationError = (r: { status: number; reason: string; message: string }) => {
    const msg = r.status === 400 && r.reason === "TOKENIZATION_ERROR" && r.message.split(".")[0];
    switch (msg) {
        case "Token not created due to an invalid card number":
            const numberError: TokeniseValidationError = {
                location: "CARD_NUMBER",
                message: "Please enter a valid credit card number"
            };
            return numberError;
        case "Token not created as the card is expired or the expiry date does not match the date the issuing bank has on file":
            const expiryError: TokeniseValidationError = {
                location: "CARD_EXPIRY",
                message: "Card is expired or the expiry date does not match the date the issuing bank has on file"
            };
            return expiryError;
        default:
            return msg;
    }
};

export const isTokenisationError = (error: TokeniseValidationError): error is TokeniseValidationError => {
    return (error as TokeniseValidationError).location !== undefined;
};

const stringToArrayBuffer = (str: string) => {
    const buf = new ArrayBuffer(str.length);
    const bufView = new Uint8Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i++) {
        bufView[i] = str.charCodeAt(i);
    }
    return buf;
};

const getCrypto = () => {
    const subtle = (window.crypto || window.msCrypto).subtle;
    return subtle;
};

/**
 * generate the crypto key from supplied config
 * @param paymentKey
 */
const getKey = (paymentKey: TokenisationKeyResponse) => {
    return new Promise<CryptoKey>((resolve, reject) => {
        // need to remove 'use' from key prior to using
        delete paymentKey.use;
        getCrypto().importKey("jwk", paymentKey, algorithmDetails, true, ["encrypt"])
            .then(key => resolve(key))
            .catch(err => reject(err));
    });
};

/**
 * encrypt the creditCard number
 * @param pubKey
 * @param config - the CardInfo to be encrypted.
 */
const encryptPan = async (
    pubKey: CryptoKey,
    config: CardInfo,
) => {
    return new Promise<CardInfo>((resolve, reject) => {
    try {
        getCrypto().encrypt(algorithmDetails, pubKey, stringToArrayBuffer(config.cardNumber))
        .then(encryptedString => {
            const u8 = new Uint8Array(encryptedString);
            const b64 = btoa(String.fromCharCode.apply(null, Array.from(u8)));
            config.cardNumber = b64;
            resolve (config);
        }); 
    } catch (err) {
        reject(err);
    }
    });
};

/**
 * Sends the message to the CyberSource API and returns a TokenisedCard via the success event.
 * @param message
 * @param baseUrl
 */
 const sendMessageToCyberSource = (
    paymentKeyId: string,
    cardInfo: CardInfo,
    cyberSourceUrl: string
) => {
    /* example message from https://developer.cybersource.com/api/reference/api-reference.html
        {
            "keyId": "08z9hCmn4pRpdNhPJBEYR3Mc2DGLWq5j",
            "cardInfo": {
                "cardNumber": "4111111111111111",
                "cardExpirationMonth": "12",
                "cardExpirationYear": "2031",
                "cardType": "001"
            }
        }
     */
    return new Promise<TokenisedCard>((resolve, reject) => {
        const message = {
            keyId: paymentKeyId,
            cardInfo: cardInfo
        };
        fetch(cyberSourceUrl, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: headers,
            redirect: "follow",
            referrer: "no-referrer",
            body: JSON.stringify(message)
        })
            .then(response => response.json())
            .then(d => {
                const rs = d.responseStatus;
                if (rs && rs.status !== 200) {
                    if (isValidationError(rs)) {
                        const validationErrors = rs.details as TokeniseValidationError[];
                        reject(validationErrors);
                    }
                    const tokenisationError = getTokenisationError(rs);
                    if (tokenisationError) {
                        reject(tokenisationError);
                    }
                    reject(rs.message);
                }
                resolve(d as TokenisedCard);
            })
            .catch(e => {reject(e.message)});
    });
};

/**
 * Pass in unencrypted CardInfo and it will be encrypted and posted to CyberSource.
 * The success event will return the TokenisedCard if successful.
 * @param paymentKey
 * @param cardInfo
 */
export const encryptAndSendToCyberSource = async (
    paymentKey: TokenisationKeyResponse,
    cardInfo: CardInfo,
    cyberSourceUrl: string
) => {
    try {
        var key = await getKey(paymentKey);
        try {
            var enc = await encryptPan(key, cardInfo);
            return sendMessageToCyberSource(
                paymentKey.kid!,
                enc,
                cyberSourceUrl
            );     
        } catch (err) {
            throw (err);
        }       
    } catch (err) {
        throw (err);
    }        
};

export const tokeniseCreditCard = async (
    { creditCard }: PaymentState,
) => {
    return new Promise<TokenisedCard>((resolve, reject) => {
        if (creditCard) {
            getTokenisationKey()
                .then(key => {
                    const cardInfo: CardInfo = {
                        cardNumber: creditCard.cardNumber,
                        cardType: { VISA: "001", MASTERCARD: "002" }[creditCard.cardType],
                        cardExpirationMonth: creditCard.expiry.month
                                    ? creditCard.expiry.month.toString().padStart(2, "0")
                                    : '',
                        cardExpirationYear: '20' + creditCard.expiry.year //convert to 4 digit year. Will handle expiry years 2000-2099
                    };
                                        
                    resolve(encryptAndSendToCyberSource(key, cardInfo, key.url));
            })
            .catch(err => {
                reject(err);
            });
        } else {
            reject(new Error('Not a credit card payment'));
        }
    });
};