import ThemeWrapper from "components/ThemeWrapper";
import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { AppInsightsReactPlugin } from "services/AppInsights";
import Template from "./components/Template";
import { isLifestyle } from "utils";
import { ErrorContextProvider, RaiseNotFound } from "components/AppErrors";

// TODO: Setup App Insights for React
// https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript-react-plugin

const LazyRoadsideHomePage = lazy(() => import("./pages/RoadsideHomePage"));
const LazyRoadsidePurchasePage = lazy(() => import("./pages/RoadsidePurchasePage"));
const LazyLifestylePurchasePage = lazy(() => import("./pages/LifestylePurchasePage"));

const AppRoutes: React.FC<{ lifestyle: boolean }> = ({ lifestyle }) => {
    return (
        <Routes>
            <Route
                index
                element={lifestyle ? <Navigate to="join-lifestyle/information" /> : <Navigate to="join-roadside" />}
            />
            {!lifestyle && <Route path="join-roadside" element={<LazyRoadsideHomePage />} />}
            {!lifestyle && <Route path="join-roadside/*" element={<LazyRoadsidePurchasePage />} />}
            {lifestyle && <Route path="join-lifestyle/*" element={<LazyLifestylePurchasePage />} />}
            <Route path="*" element={<RaiseNotFound />} />
        </Routes>
    );
};

const App: React.FC = () => {
    const lifestyle = isLifestyle();

    return (
        <AppInsightsContext.Provider value={AppInsightsReactPlugin}>
            <ThemeWrapper>
                <Router>
                    <ErrorContextProvider>
                        <Suspense fallback={<Template>{/*loading...*/}</Template>}>
                            <AppRoutes lifestyle={lifestyle} />
                        </Suspense>
                    </ErrorContextProvider>
                </Router>
            </ThemeWrapper>
        </AppInsightsContext.Provider>
    );
};

export default App;
