// These separate error child classes help us render content differently based on the instanceof
// Also useful with differentiating the kind of error that turns up in the appInsights log

// SHould the NotFoundError be different for roadside/lifestyle
// So we can render the correct go back to homepage link
// Or just keep using the condition we have that checks ifLifestyle in the NotFoundPage.tsx
export class NotFoundError extends Error {
    constructor(message: string = "The page was not found") {
        super(message);
        this.name = this.constructor.name;
    }
}

export class APIServiceError extends Error {
    constructor(message?: string) {
        super(message);
        this.name = this.constructor.name;
    }
}

export class DatoCMSServiceError extends Error {
    constructor(channelErrorData: any, message?: string) {
        super(message);
        this.name = this.constructor.name;
        this.channelErrorData = channelErrorData;
    }
    private channelErrorData: any;
}

export class QASServiceError extends Error {
    constructor(message?: string) {
        super(message);
        this.name = this.constructor.name;
    }
}
