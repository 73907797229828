import React from "react";

interface ErrorBoundaryProps {
    raiseError: (appError: Error) => void;
    children?: React.ReactNode;
}

// This is a classful component because
// React hooks don't cover the error boundary use case yet https://reactjs.org/docs/hooks-faq.html#do-hooks-cover-all-use-cases-for-classes

/**
 * Error boundaries are intended for unplanned errors only (https://github.com/facebook/create-react-app/issues/3627#issuecomment-352730687)
 * and they still show the error overlay in development
 * In the production build it would display the appriopriate error page
 * You should properly catch and raiseError(expectedError) or render the correct <RenderError />
 * Errors thrown directly from the children of this boundary would be caught but if you are doing that without reason then you should be raising the error :-P
 */
export class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
    componentDidCatch(error: Error, _errorInfo: React.ErrorInfo) {
        this.props.raiseError(error);
    }

    render() {
        return this.props.children;
    }
}
